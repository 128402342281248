<template>
    <div class="top-nav">
       
       <img src="../assets/png/logo.png" class="logo-activity" style="" alt="">
       
    </div>
    <div data-v-47f4cc84="" class="login__container-heading1">
       <h1 data-v-47f4cc84="" class="login__container-heading__title">Activity</h1>
       <div data-v-47f4cc84="" class="login__container-heading__subTitle">
          <div data-v-47f4cc84="">Please remember to follow the event page</div>
          <div data-v-47f4cc84="">We will launch user feedback activites from time to time</div>
       </div>
    </div>
    
    <div class="container-fluid" style="margin-top: 0px;">
       <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
             <div class="row pb-5">
                <div class="col-12 pb-2">
                    <img src="../assets/png/activity.png" class="activity-img" alt="">
                </div>
                <div class="col-6" @click="$router.push('ForgotPassword')">
                    <div class="card">
                        <img class="card-img-top" src="../assets/png/signInBanner-33f86d3f.png" alt="Card image cap">
                        <h5 class="float-left pt-2 pl-2" style="font-size: 15px;">Gifts</h5>
                        <p class="card-text float-left pl-2 pb-3" style="font-size: 12px;">Enter the redemption code to receive gift rewards</p>
                        </div>
                </div>
                <div class="col-6" @click="$router.push('ForgotPassword')">
                    <div class="card">
                        <img class="card-img-top" src="../assets/png/giftRedeem-45917887.png" alt="Card image cap">
                        <h5 class="float-left pt-2 pl-2" style="font-size: 15px;">Attendance Bonus</h5>
                        <p class="card-text float-left pb-3 pl-2" style="font-size: 12px;">Enter the redemption code to receive gift rewards</p>
                        </div>
                </div>
                
             </div>
          </div>
          <div class="col-md-4"></div>
       </div>
    </div>
    <!-- footer-nav -->
    <div class="footer-nav" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div class="footer-nav-item" @click="$router.push('home')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-home">
             <use xlink:href="#icon-home"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Home</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item active" @click="$router.push('activity')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
             <use xlink:href="#icon-activity"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Activity</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('promotion')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
             <use xlink:href="#icon-promotion"></use>
          </svg>
          <div data-v-6ab3f23e="" class="promotionBg"></div>
          <span data-v-6ab3f23e="">Promotion</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('wallet')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
             <use xlink:href="#icon-wallet"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Wallet</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('Mine')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-main">
             <use xlink:href="#icon-main"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Account</span>
       </div>
    </div>
 </template>
 <script>
    export default {
        name: "AcivityView",
      
    }
    
 </script>