<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
   <div data-v-36cc3380="" class="Recharge__container">
      <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  
                  <div data-v-12a80a3e="" class="navbar__content-title nav-recharge">Deposit</div>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-right">
                  <div data-v-36cc3380="" class="title nav-ttt-recharge" @click="$router.push('rechargehistory')">Deposit history</div>
               </div>
            </div>
         </div>
      </div>
      <!-- 余额组件 -->
      <div data-v-98c90f53="" data-v-36cc3380="" class="balanceAssets">
         <div data-v-98c90f53="" class="balanceAssets__header">
            <div data-v-98c90f53="" class="balanceAssets__header__left"><img data-v-98c90f53="" src="../assets/png/balance-e39ce400.png"> Balance</div>
         </div>
         <div data-v-98c90f53="" class="balanceAssets__main">
            <p data-v-98c90f53="">₹{{ Number(userdetails.balance).toFixed(2) }}</p>
            <img data-v-98c90f53="" src="../assets/png/refresh-8e0efe26.png" alt="">
         </div>
         <!-- <div class="balanceAssets__tip">
            <img :src="getIconsPublic('wallet/recharge', 'cip')" alt="" />
            </div> -->
      </div>
      <!-- 支付方式选择菜单 -->
      <div data-v-4f3d8608="" data-v-36cc3380="">
         
         <div data-v-4f3d8608="" class="Recharge__container-tabcard">
            <div data-v-4f3d8608="" class="Recharge__container-tabcard__items" @click="$router.push('Recharge')">
               <div data-v-4f3d8608="" class="centers">
                  
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__top"><img data-v-4f3d8608="" class="img" src="https://ossimg.forpicStore777.top/rajalottery/payNameIcon/payNameIcon2_202404301344236teh.png" alt=""></div>
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__bot">
                     UPI <!-- <span v-if="item.maxRechargeRifts && item.maxRechargeRifts > 0">{{ item?.maxRechargeRifts * 100 }}%</span> -->
                  </div>
               </div>
            </div>
            <div data-v-4f3d8608="" class="Recharge__container-tabcard__items active">
               <div data-v-4f3d8608="" class="centers" @click="$router.push('UpiDeposit')">
                  
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__top"><img data-v-4f3d8608="" class="img" src="https://ossimg.forpicStore777.top/rajalottery/payNameIcon/payNameIcon_20240503114801qwku.png" alt=""></div>
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__bot">
                     UPI-QR <!-- <span v-if="item.maxRechargeRifts && item.maxRechargeRifts > 0">{{ item?.maxRechargeRifts * 100 }}%</span> -->
                  </div>
               </div>
            </div>
            <div data-v-4f3d8608="" class="Recharge__container-tabcard__items">
               <div data-v-4f3d8608="" class="centers" @click="$router.push('UsdtDeposit')">
                  
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__top"><img data-v-4f3d8608="" class="img" src="https://ossimg.forpicStore777.top/rajalottery/payNameIcon/payNameIcon_202401241504247a6g.png" alt=""></div>
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__bot">
                     USDT <!-- <span v-if="item.maxRechargeRifts && item.maxRechargeRifts > 0">{{ item?.maxRechargeRifts * 100 }}%</span> -->
                  </div>
               </div>
            </div>
         </div>
         
      </div>
      <div data-v-9e03166f="" class="Recharge__content">
         <div data-v-9e03166f="" class="Recharge__content-paymoney boxStyle">
            <div data-v-9e03166f="" class="Recharge__content-paymoney__title">
               <svg data-v-9e03166f="" class="svg-icon icon-saveWallet">
                  <use xlink:href="#icon-saveWallet"></use>
               </svg>
               <p data-v-9e03166f="">Deposit amount</p>
            </div>
            <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list">
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(300)">
                     <span data-v-9e03166f="">₹</span> 300
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(500)">
                     <span data-v-9e03166f="">₹</span> 500
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(1000)">
                     <span data-v-9e03166f="">₹</span> 1k
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(5000)">
                     <span data-v-9e03166f="">₹</span> 5K
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(10000)">
                     <span data-v-9e03166f="">₹</span> 10K
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(50000)">
                     <span data-v-9e03166f="">₹</span> 50K
                  </div>
                  
               </div>
            </div>
            
            <div data-v-9e03166f="" class="Recharge__content-paymoney__money-input">
               <div data-v-9e03166f="" class="place-div">₹</div>
               <div data-v-9e03166f="" class="van-cell van-field amount-input" modelmodifiers="[object Object]">
                  <!----><!---->
                  <div class="van-cell__value van-field__value">
                     <div class="van-field__body">
                        <input type="tel" inputmode="numeric" required v-model="inputValue" id="amount" class="van-field__control" style="border:none; outline: none; background-color: transparent;" placeholder="Please enter the amount" /><!----><!----><!---->
                     </div>
                     <!----><!---->
                  </div>
                  <!----><!---->
               </div>
               
               <div data-v-9e03166f="" class="place-right"><img data-v-9e03166f="" src="/assets/png/clean-82487515.png" alt=""></div>
               
            </div>
            
            <div v-if="inputValue!==''">
               <div data-v-9e03166f="" class="Recharge__container-rechageBtn rechage_active" @click="proceed">Deposit</div>
            </div>
            <div v-else>
               <div data-v-9e03166f="" class="Recharge__container-rechageBtn" >Deposit</div>
            </div>
         </div>
      </div>
      <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
         <div class="van-toast__text">Minimum Recharge 300</div>
      </div>
      <div data-v-7cba6004="" data-v-36cc3380="" class="Recharge__container-intro">
         <div data-v-7cba6004="" class="Recharge__container-intro__title">
            <div data-v-7cba6004="" class="img">
               <svg data-v-7cba6004="" class="svg-icon icon-shuoming">
                  <use xlink:href="#icon-shuoming"></use>
               </svg>
            </div>
            <p data-v-7cba6004="">Recharge instructions</p>
         </div>
         <div data-v-7cba6004="" class="Recharge__container-intro__lists">
            <div data-v-7cba6004="" class="item">
               <p data-v-7cba6004="">If the transfer time is up, please fill out the deposit form again.</p>
               <p data-v-7cba6004="">The transfer amount must match the order you created, otherwise the money cannot be credited successfully.</p>
               <p data-v-7cba6004="">If you transfer the wrong amount, our company will not be responsible for the lost amount!</p>
               <p data-v-7cba6004="">Note: do not cancel the deposit order after the money has been transferred.</p>
            </div>
         </div>
      </div>
      <div data-v-9f5f4114="" data-v-36cc3380="" class="record__main" payid="2">
         <div data-v-9f5f4114="" class="record__main-title">
            <svg data-v-9f5f4114="" class="svg-icon icon-historyHead">
               <use xlink:href="#icon-historyHead"></use>
            </svg>
            <span data-v-9f5f4114="">Deposit history</span>
         </div>
         
         <div data-v-9f5f4114="">
            <div data-v-9f5f4114="" class="record__main-info" v-for="row in rechargerecord" :key="row.id">
               <div data-v-9f5f4114="" class="record__main-info__title flex_between">
                  <div data-v-9f5f4114="" class="recharge_text">Deposit</div>
                  <div data-v-9f5f4114="" class="flex_between">
                     <div data-v-9f5f4114="" class="text-warning text-uppercase" style="font-weight: 700" v-if="row.status==='unpaid'">To Be Process</div>
                     <div data-v-9f5f4114="" class="rechargeFail text-uppercase" style="font-weight: 700" v-if="row.status==='failed'">Failed</div>
                     <div data-v-9f5f4114="" class="success text-uppercase" style="font-weight: 700" v-if="row.status==='success'">Success</div>
                     
                  </div>
               </div>
               <div data-v-9f5f4114="" class="record__main-info__money item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Balance</span><span data-v-9f5f4114="">₹{{ Number(row.recharge).toFixed(2) }}</span></div>
               <div data-v-9f5f4114="" class="record__main-info__type item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Type</span><span data-v-9f5f4114="">Ice-Upi</span></div>
               <div data-v-9f5f4114="" class="record__main-info__time item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Time</span><span data-v-9f5f4114="">{{ row.created_at }}</span></div>
               <div data-v-9f5f4114="" style="margin-top: -65px;" class="record__main-info__orderNumber item flex_between">
                  <span data-v-9f5f4114="" style="font-size: 0.9rem">Order number</span>
                  <div data-v-9f5f4114="">
                     <span data-v-9f5f4114="" style="font-size: 0.9rem">{{ row.rand }}</span>
                     <svg data-v-9f5f4114="" class="svg-icon icon-copy" width="20px" alt="">
                        <use xlink:href="#icon-copy"></use>
                     </svg>
                  </div>
               </div>
            </div>
            
            <div data-v-9f5f4114="" @click="$router.push('rechargehistory')" class="record__main-more">All history</div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'UpiDepositView',
        data() {
        return {
          inputValue: '',
          userdetails: {
               username: null,
               balance: null,
               page: 1,
               total22: 0
           },
           rechargerecord: {

            },
        };
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      methods: {
        updateInput(value) {
          this.inputValue = value;
        },
         goBack() {
            window.history.back();
         },
         getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.tcgame.online/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.balance = this.Users[0].balance;
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.tcgame.online/trova/src/api/bet.php?action=rechargerecord&user=' + this.userdetails.username + '&page1=' + this.userdetails.page).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
        },
         proceed() {
               this.username = localStorage.getItem('username');
               if (document.getElementById("amount").value > 299) {
                  var link = "https://manager.tcgame.online/trova/src/api/pay.php?user="+this.username+"&am="
                  // var link = "https://powerindia.in/Payment-step/index.php?uid="+this.username+"&amount="
                  var amount = document.getElementById("amount").value;
                  var payurl = link.concat(amount);
                  window.open(payurl, "_blank");
               }
               else {
                  var x = document.getElementById("snackbar");
                  x.style.display = "";
                  setTimeout(function e() {  x.style.display="none"; }, 1000);
               }
            }
      }
    }
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>